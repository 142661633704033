<template>
    <div>
        <Header/>
        <div class=" v_content">
            <div style="padding:15px;">
                <div class="justify-content-md-center" style="max-width: 150vh;margin: auto;">
                    <div style="text-align:left;margin-top:20px;">
                        <h5><strong>{{$t('download_header')}}</strong></h5>
                    </div>
                    <div style="width:100%;height:60px;">
                        <div class="select_year" >
                            <i class="fa fa-search"></i>
                            <b-form-select v-model="yearSelect" :options="yearDatas" class="mb-3" @change="selectYear()">
                                <template #first>
                                    <b-form-select-option :value="''" disabled>{{$t('all')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </div>
                    </div>
                    <b-row align-h="start" style="margin-top:50px;">
                        <b-col v-for="data,index of booklist" :key="index" style="margin-bottom:60px;">
                            <section>
                                <div class="book" :id="'book_' + index" @click="download(data['bookPath'])">
                                    <img :src="'http://air4thai.com/tagoV2/' + data['bookCoverPath']">
                                </div>
                                <b-tooltip :target="'book_' + index" triggers="hover">
                                    {{data['bookName']}}
                                </b-tooltip> 
                            </section>
                        </b-col>
                    </b-row>
                    <hr>
                    <div style="text-align:left;">
                        <h5><strong>งานวิจัย</strong></h5>
                    </div>
                    <b-list-group style="text-align:left;">
                        <b-list-group-item v-for="data,index of researchList" :key="index" @click="download(data['bookPath'])" style="cursor: pointer;">
                            <i class="fas fa-file-download"></i> {{data['bookName']}}
                        </b-list-group-item>
                    </b-list-group>
                    <!-- <b-row align-h="start" style="margin-top:50px;">
                        <b-col v-for="data,index of researchDatas" :key="index" style="margin-bottom:60px;">
                            <section>
                                <div class="book" :id="'book_' + index" @click="download(data['bookPath'])">
                                    <img :src="'http://air4thai.com/tagoV2/' + data['bookCoverPath']">
                                </div>
                                <b-tooltip :target="'book_' + index" triggers="hover">
                                    {{data['bookName']}}
                                </b-tooltip> 
                            </section>
                        </b-col>
                    </b-row> -->
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import Header from '../containers/Header'
import Footer from '../containers/Footer'

export default {
    name : 'download',  
    components : {
        Header,
        Footer
    } ,
    data () {
        return {
            yearSelect:'',
            yearDatas:[],
            bookDatas:[],
            researchDatas:[]
        }
    },
    methods : {
        selectYear(){

        },
        download(url){
            window.open("http://air4thai.com/tagoV2/"+url);
        },
        getBook(){
            fetch('http://air4thai.com/forweb/getBooks.php').then(response => response.json())
            .then(data => {
                //console.log('data',data)
                // this.bookDatas = data['books']
                this.bookDatas = []
                this.researchDatas = []
                this.yearSelect = ''
                // this.yearDatas = [{ value: '', text: 'ทั้งหมด' }]
                for(var a=0;a<data['books'].length;a++){
                    if(data['books'][a].categoryID == "2"){
                        this.bookDatas.push(data['books'][a])
                    }
                    if(data['books'][a].categoryID == "3"){
                        this.researchDatas.push(data['books'][a])
                    }
                    // set year dropdown //
                    var add_data = true
                    for(var b=0;b<this.yearDatas.length;b++){
                        if(this.yearDatas[b].value == data['books'][a].bookYear)
                        {
                            add_data = false
                            break
                        }
                    }
                    if(add_data)
                        this.yearDatas.push({ value: data['books'][a].bookYear, text: data['books'][a].bookYear })
                }
            })
            .catch((err) => {
                this.$swal({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    title: 'Error!',
                    text: err,
                    icon: 'error'
                })
            })
        }
    },
    computed : {
        booklist () {
            if (this.yearSelect == '')
                return this.bookDatas

            var return_book = []
            for(var a=0;a<this.bookDatas.length;a++){
                if(this.bookDatas[a].bookYear == this.yearSelect){
                    return_book.push(this.bookDatas[a])
                }
            }
            return return_book
        },
        researchList () {
            if (this.yearSelect == '')
                return this.researchDatas
            var return_esearch = []
            for(var a=0;a<this.researchDatas.length;a++){
                if(this.researchDatas[a].bookYear == this.yearSelect){
                    return_esearch.push(this.researchDatas[a])
                }
            }
            return return_esearch
        }
    },
    created () {
        this.getBook()
    }
}
</script>

<style lang="scss" scoped>
section{
    display: flex;
    justify-content:  center;
    align-items: center;
    // transform-style: preserve-3d;
    // -webkit-transform-style: preserve-3d;
    overflow: visible !important;
    perspective: 1000px;
}
section .book:hover {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    // transform: rotateY(-30deg);
    // box-shadow: 0px 20px 20px rgba(0,0,0,0.2);
}
section .book::before {
    content: '';
    position: absolute;
    width: 30px;
    height: 100%;
    // transform-origin: left;
    background: #fff;
    // background-position: center;
    // transform: rotateY(90deg);
}
section .book::before {
    /* ... */
    transform: translateX(calc(68px - 30px / 2))
                rotateY(90deg)
                translateX(calc(30px / 2));
    -webkit-transform: translateX(calc(68px - 30px / 2))
             rotateY(90deg)
             translateX(calc(30px / 2));
}
section .book::after {
  content: ' ';
  position: absolute;
  left: 0;
  width: 140px;
  height: 200px;
  background: #01060f;
  box-shadow: -5px 0 30px 5px rgba(0,0,0,0.5);
//   box-shadow: 0px -20px 20px rgba(0,0,0,0.2);
}
section .book::after {
  /* ... */
  transform: translateZ(-30px);
  -webkit-transform: translateZ(-30px);
}
section .book{
    position: relative;
    width: 140px;
    height: 200px;
    // box-shadow: 20px 20px 20px rgba(0,0,0,0.2);
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    transition: 0.5s;
    cursor: pointer;
    transform: rotateY(-30deg);
}
section .book img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
@media only screen and (min-width : 850px) {
    .select_year{
    display: flex;
    width: 220px;
    float: right;
    margin-top:20px;
    i {
        display: inline;
        font-size:22px;
        margin-top:5px;
        margin-right: 10px;
    }
}
}
@media only screen and (max-width : 850px) {
    .select_year{
    display: flex;
    width: 220px;
    float: left;
    margin-top:20px;
    i {
        display: inline;
        font-size:22px;
        margin-top:5px;
        margin-right: 10px;
    }
}
}

</style>